<template>
    <el-container>
        <el-header>
            <HeadBar></HeadBar>
        </el-header>
        <el-main>
            <AppMain></AppMain>
        </el-main>
        <div class="right-style">
            <div class="div-style"  @mouseover="mouseoverImg1" @mouseout="mouseoutImg1">
                <img :src="currentImage1"/>
                <div>服务热线</div>
                <div class="service-hotline" v-show="show1">
                    <div >400-656-0008</div>
                    <div class="text-style">全国服务热线</div>
                </div>
            </div>
            <div class="halving-line-style"></div>
            <div class="div-style" @mouseover="mouseoverImg2" @mouseout="mouseoutImg2">
                <img :src="currentImage2"/>
                <div>客服微信</div></div>
                <div class="customer-service-wechat" v-show="show2">
                    <img src="@/assets/layout/ewm.jpg"/>
                    <div class="text-style">扫码添加客服</div>
                </div>
            <div class="halving-line-style"></div>
            <div class="div-style" @mouseover="mouseoverImg3"  @click="to" @mouseout="mouseoutImg3">
                <img :src="currentImage3"/>
                <div>意见反馈</div>
            </div>
            <div class="div-style" @mouseover="mouseoverImg4"  @click="to" @mouseout="mouseoutImg4">
                <img :src="currentImage4"/>
                <div>软件下载</div>
                <div class="software-download" v-show="show4">
                    <div class="img1">
                        <img src="@/assets/layout/zhgd.png"/>
                    <div class="text-style">智慧工地App</div>
                    </div>
                    <div class="img2">
                        <img src="@/assets/layout/gdzn.png"/>
                    <div class="text-style">工地智脑App</div>
                    </div>
                </div>
            </div>
        </div>
    </el-container>
</template>

<script>
import HeadBar from "@/layout/componentss/header.vue";
// import SlideBar from "@/layout/componentss/sliderBar.vue";
import AppMain from "@/layout/componentss/main.vue";
export default {
    name: "myLayout",
    components: {
        HeadBar,
        // SlideBar,
        AppMain
    },
    data() {
        return {
            show1:false,
            show2:false,
            show4:false,
            currentImage1: require('@/assets/layout/dh.png'),
            currentImage2: require('@/assets/layout/wx1.png'),
            currentImage3: require('@/assets/layout/ywfh1.png'),
            currentImage4:require('@/assets/layout/download.png')
        }
    },
    methods: {
        mouseoverImg1() {
            this.show1 = true
            this.currentImage1 = require('@/assets/layout/dh2.png');
        },
        mouseoverImg2() {
            this.show2 = true
            this.currentImage2 = require('@/assets/layout/wx2.png');
        },
        mouseoverImg3() {
            this.currentImage3 = require('@/assets/layout/ywfh2.png');
        },
        mouseoverImg4() {
            this.show4 = true
            this.currentImage4 = require('@/assets/layout/download2.png');
        },
        mouseoutImg1(){
            this.show1 = false
            this.currentImage1 = require('@/assets/layout/dh.png');
        },
        mouseoutImg2(){
            this.show2 = false
            this.currentImage2 = require('@/assets/layout/wx1.png');
        },
        mouseoutImg3(){
            this.currentImage3 = require('@/assets/layout/ywfh1.png');
        },
        mouseoutImg4(){
            this.show4 = false
            this.currentImage4 = require('@/assets/layout/download.png');
        },

        to() {
            this.$router.push({ path: "/aboutUs" });
            },
    }
}
</script>

<style scoped>
.el-container{
    width: 100%;
    height: 100%;
}
.el-header {
    padding: 0 !important;
}

.el-main {
    padding: 0 !important;
}
.right-style{
    position: absolute;
    right: 16px;
    top: 50%;
    z-index: 900;
    background-color: #fff;
    box-shadow: 3px 0px 7px 0px #6e6e6e;
    transform: translate(0%, -50%);
}
.halving-line-style{
    border: 1px solid #ECECEC;
}
.div-style{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: PingFang-SC, PingFang-SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 30px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding: 0 14px;
    cursor: pointer;
}
.div-style:hover{
    background-color: #0076FF;
    color: #fff;
}
.div-style img{
    margin-top: 13px;
}
.service-hotline{
    width: 153px;
    height: 69px;
    background-color: #fff;
    position: fixed;
    right: 85px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: #0076FF;
    padding: 10px 18px;
    text-align: left;
    box-shadow: 3px 0px 7px 0px #6e6e6e;
}
.text-style{
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 20px;
    font-style: normal;
    text-transform: none;
    margin-bottom: -5px;
}

.customer-service-wechat{
    width: 102px;
    height: 118px;
    transform: translate(0%, -55%);          
    background-color: #fff;
    position: fixed;
    right: 85px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: #0076FF;
    text-align: center;
    box-shadow: 3px 0px 7px 0px #6e6e6e;
}
.customer-service-wechat img{
    width: 73px;
    height: 73px;
    margin-top: 14px;
}
.software-download{
    width: 102px;
    height: 226px;
    transform: translate(0%, 30%);
    background-color: #fff;
    position: fixed;
    right: 85px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: #0076FF;
    text-align: center;
    box-shadow: 3px 0px 7px 0px #6e6e6e;
}

.software-download img{
    width: 73px;
    height: 71px;
}
/* .software-download img{
    width: 71px;
    height: 70px;
} */
</style>