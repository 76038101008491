<template>
  <div class="myBox">
    <div class="myBox-up">
      <div class="myBox-row">
        <div class="myBox-col">
          <div class="myBox-one-name">产品与服务</div>
          <div class="myBox-one-chunk">
            <div class="myBox-one-right">
              <el-link @click="to('/moveService/digital')" :underline="false" target="_blank">OA协同办公管理系统</el-link>
              <el-link @click="to('/moveService/standardSpecification')" :underline="false"
                target="_blank">数字化管理基础平台</el-link>
              <el-link @click="to('/moveService/realname')" :underline="false" target="_blank">建筑工人实名制管理系统</el-link>
              <el-link @click="to('/moveService/surroundings')" :underline="false" target="_blank">环境监测管理系统</el-link>
              <el-link @click="to('/moveService/loadhook')" :underline="false" target="_blank">塔机吊钩导航系统</el-link>
              <el-link @click="to('/moveService/digitalTwin')" :underline="false" target="_blank">数字孪生平台</el-link>

            </div>
            <div class="myBox-one-right">
              <el-link @click="to('/moveService/lifecycle')" :underline="false" target="_blank">档案管理系统</el-link>
              <el-link @click="to('/moveService/overview')" :underline="false" target="_blank">分布式制能制造系统</el-link>
              <el-link @click="to('/moveService/workerwage')" :underline="false" target="_blank">建筑工人工资管理</el-link>
              <el-link @click="to('/moveService/towercrane')" :underline="false" target="_blank">塔机安全监控管理系统</el-link>
              <el-link @click="to('/moveService/qualityinspection')" :underline="false" target="_blank">质量检测管理平台</el-link>
            </div>

            <div class="myBox-one-right">
              <el-link @click="to('/moveService/visualization')" :underline="false" target="_blank">大数据共享服务平台</el-link>
              <el-link @click="to('/moveService/colony')" :underline="false" target="_blank">门户网站建设及应用平台</el-link>
              <el-link @click="to('/moveService/video')" :underline="false" target="_blank">视频监控管理系统</el-link>
              <el-link @click="to('/moveService/lifter')" :underline="false" target="_blank">升降机安全监控管理系统</el-link>
              <el-link @click="to('/moveService/deeppit')" :underline="false" target="_blank">深基坑监测管理系统</el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="image-container">
        <img class='logo' src="@/assets/moveViews/homePageL/logo.png" alt="">
      </div>

      <div class="middle-box">
        <div class="myBox-one-chunk middle-right">
          <div class="myBox-one-left">
            <el-image class="public-pic" :src="require('@/assets/components/commonality/vcode copy.png')" />
          </div>
          <div class="right">
            <div class="dh-style">400-656-0008</div>
            <div class="text">全国服务热线</div>
            <div class="yx-style">jinchui1002@163.com</div>
            <div class="text">邮箱</div>
          </div>
        </div>
      </div>
    </div>
    <div class="myBox-reference">
      <el-link href="http://beian.miit.gov.cn" :underline="false" target="_blank">湘ICP备19012616号</el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "myFooter",
  methods: {
    to(url) {
      this.$router.push({ path: url });
    },
  },
}
</script>

<style scoped>
.myBox {
  height: 100%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}


.myBox-up {
  height: 55%;
  background-color: #222429;
}

.myBox-row {
  margin-top: calc(100vh * (30 / 870));
  margin-left: calc(100vw * (30 / 540));
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myBox-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.myBox-one-name {
  font-size: calc(100vw * (18 / 540));
  font-family: HarmonyOS_Sans_SC_Bold;
  line-height: calc(100vh * (30 / 870));
}

.myBox-one-chunk {
  flex: 1;
  display: flex;
}

.myBox-one-left,
.myBox-one-right {
  display: flex;
  flex-direction: column;
  line-height: calc(100vh * (66.5 / 870));
}

.el-link {
  color: #CCCCCC !important;
  text-align: left;
  justify-content: start;
  font-size: calc(100vw * (12 / 540));
  font-family: HarmonyOS_Sans_SC;
  width: calc(100vw * (140 / 540));
  line-height: calc(100vh * (16 / 870));
  margin-right: calc(100vw * (40 / 540));
  margin-top: calc(100vh * (8 / 870));
}

.public-pic {
  width: calc(100vw * 300/ 1920);
  height: calc(100vw * 300/ 1920);
  font-size: 0;
}

.myBox-reference {
  height: 10%;
  background-color: #151922;
  display: flex;
  padding-left: 160px;
  align-items: center;
  font-size: calc(100vw * 14 / 1920);
}

.dh-style {
  font-family: HarmonyOS_Sans_SC_Bold;
  font-size: calc(100vw * 60/ 1920);
  color: #FFFFFF;
  line-height: calc(100vw * 80/ 1920);
  font-weight: 700;
}

.yx-style {
  font-family: HarmonyOS_Sans_SC_Bold;
  font-size: calc(100vw * 40/ 1920);
  color: #FFFFFF;
  line-height: calc(100vw * 80/ 1920);
  font-weight: 700;
}

.text {
  font-family: HarmonyOS_Sans_SC;
  font-size: calc(100vw * 30/ 1920);
  line-height: calc(100vw * 80/ 1920);
}

.right {
  margin-top: -1px;
  margin-left: calc(100vw * 40 / 1920);
}

.middle {
  background-color: #222429;
  display: flex;
  flex: wrap;
  flex-direction: row;
  width: 100%;
  height: 35%;
  padding-top: calc(100vh * (10 / 870));
}

.image-container {
  width: calc(100vw * 600/ 1920);
}

.logo {
  width: calc(100vw * 600/ 1920);
  margin-left: 20px;
  margin-top: 20px;
}

.middle-right {
  margin-left: 80px;
}
</style>