import router from "@/router/index.js"
function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}
router.beforeEach((to, from, next) => {
    //判断是否有jurisdiction这个属性,如果有的话进一步判断,如果没有直接放行
    if (to.path != '/' && !to.meta.jurisdiction) {
        //判断是移动端还是pc端,跟jurisdiction属性是否对应,如不对应跳转对应端的首页
        next('/')
    } else {
        next();
    }
    // console.log(to, from, next)
})

router.afterEach((to) => {
    // set page title
    if (to != null && to.meta != null && to.meta['title'] !== undefined && to.meta.title != null) {
        document.title = to.meta.title + ' - 金锤科技'
    } else {
        document.title = '金锤科技'
    }
})