//该文件专门用于创建整个路由器
import vueRouter from "vue-router"
import layout from "@/layout/index.vue"
import moveViewsIndex from "@/layout/moveViewsIndex.vue"

/**
 * 待定  jurisdiction 为控制权限,PC为PC端,move:为移动端 
 * 
 */
//为啥加下面这段代码 自己看
//https://wenku.baidu.com/view/16f9453368d97f192279168884868762cbaebb48.html
const originalPush = vueRouter.prototype.push
// @ts-ignore
vueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    // @ts-ignore
    return originalPush.call(this, location).catch(err => err)
}

const routersPC = [{
    path: '/',
    component: layout,
    children: [
        {
            path: '/',
            // component: () => import('@/pcViews/homePage/HomePage.vue'),
            component: () => import('@/pcViews/homePage/index.vue'),
            meta: {
                title: 'PC端首页',
                jurisdiction: "PC"
            },

        },
        {
            path: '/download',
            component: () => import('@/pcViews/download/index.vue'),
            meta: {
                title: '软件下载',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution',
            // component: () => import('@/pcViews/solution/index.vue'),
            component: () => import('@/pcViews/solution/solution.vue'),
            meta: {
                title: '解决方案',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/digital',
            component: () => import('@/pcViews/solution/digital/index.vue'),
            meta: {
                title: 'OA协同办公管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/lifecycle',
            component: () => import('@/pcViews/solution/lifecycle/index.vue'),
            meta: {
                title: '档案管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/visualization',
            component: () => import('@/pcViews/solution/visualization/index.vue'),
            meta: {
                title: '大数据共享服务平台',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/realname',
            component: () => import('@/pcViews/solution/realname/index.vue'),
            meta: {
                title: '建筑工人实名制管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/workerwage',
            component: () => import('@/pcViews/solution/workerwage/index.vue'),
            meta: {
                title: '建筑工人工资管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/towercrane',
            component: () => import('@/pcViews/solution/towercrane/index.vue'),
            meta: {
                title: '塔机安全监控管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/lifter',
            component: () => import('@/pcViews/solution/lifter/index.vue'),
            meta: {
                title: '升降机安全监控管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/loadhook',
            component: () => import('@/pcViews/solution/loadhook/index.vue'),
            meta: {
                title: '塔机吊钩导航系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/surroundings',
            component: () => import('@/pcViews/solution/surroundings/index.vue'),
            meta: {
                title: '环境监测系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/video',
            component: () => import('@/pcViews/solution/video/index.vue'),
            meta: {
                title: '视频监控管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/concrete',
            component: () => import('@/pcViews/solution/concrete/index.vue'),
            meta: {
                title: '混凝土质量动态追踪及检测监管系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/qualityinspection',
            component: () => import('@/pcViews/solution/qualityinspection/index.vue'),
            meta: {
                title: '质量检测管理平台',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/deeppit',
            component: () => import('@/pcViews/solution/deeppit/index.vue'),
            meta: {
                title: '深基坑监测管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/colony',
            component: () => import('@/pcViews/solution/colony/index.vue'),
            meta: {
                title: '门户网站建设及应用平台 [机构/企业]',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/contract',
            component: () => import('@/pcViews/solution/contract/index.vue'),
            meta: {
                title: '合同管理系统',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/digitalTwin',
            component: () => import('@/pcViews/solution/digitalTwin/index.vue'),
            meta: {
                title: '数字孪生平台',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/overview',
            component: () => import('@/pcViews/solution/overview/index.vue'),
            meta: {
                title: '分布式制能制造系统（物联感知平台）',
                jurisdiction: "PC"
            },
        },
        {
            path: '/solution/standardSpecification',
            component: () => import('@/pcViews/solution/standardSpecification/index.vue'),
            meta: {
                title: '数字化管理基础平台',
                jurisdiction: "PC"
            },
        },
        {
            path: '/aboutUs',
            // component: () => import('@/pcViews/aboutUs/aboutUs.vue'),
            component: () => import('@/pcViews/aboutUs/index.vue'),
            meta: {
                title: '关于我们',
                jurisdiction: "PC"
            },
        },
        {
            path: '/successfulCase',
            component: () => import('@/pcViews/successfulCase/index.vue'),
            meta: {
                title: '成功案例',
                jurisdiction: "PC"
            },
        },
        {
            path: '/successfulCaseList',
            component: () => import('@/pcViews/successfulCase/list/index.vue'),
            meta: {
                title: '成功案例',
                jurisdiction: "PC"
            },
        },
        {
            path: '/successfulCaseXinjiang',
            component: () => import('@/pcViews/successfulCase/xinjiang/index.vue'),
            meta: {
                title: '成功案例',
                jurisdiction: "PC"
            },
        },
        {
            path: '/successfulCaseSmartSite',
            component: () => import('@/pcViews/successfulCase/smartSite/index.vue'),
            meta: {
                title: '成功案例',
                jurisdiction: "PC"
            },
        },
        {
            path: '/propagandize',
            component: () => import('@/pcViews/propagandize/index.vue'),
            meta: {
                title: '宣传视频',
                jurisdiction: "PC"
            },
        },
        {
            path: '/propagandize/details',
            component: () => import('@/pcViews/propagandize/videoDetails/videoDetails.vue'),
            meta: {
                title: '视频详情',
                jurisdiction: "PC"
            },
        },
        {
            path: '/journalism',
            component: () => import('@/pcViews/newsInformation/index.vue'),
            meta: {
                title: '新闻资讯',
                jurisdiction: "PC"
            },
        },
        {
            path: '/journalism/allNewsParticulars',
            component: () => import('@/pcViews/newsInformation/allNewsParticulars/allNewsParticulars.vue'),
            meta: {
                title: '全部新闻详情',
                jurisdiction: "PC"
            },
        },
    ]
}]

const routersM = [{
    path: '/',
    component: moveViewsIndex,
    children: [
        // {
        //     path: '/min',
        //     component: () => import('@/moveViews/homePage/homeIndex.vue'),
        //     meta: {
        //         title: '首页',
        //         jurisdiction: "move"
        //     },
        // },
        {
            path: '/',
            component: () => import('@/moveViews/homePage/homeIndexNew.vue'),
            meta: {
                title: '首页',
                jurisdiction: "move"
            },
        },
        // {
        //     path: '/moveSolution',
        //     component: () => import('@/moveViews/moveSolution/index.vue'),
        //     meta: {
        //         title: '解决方案',
        //         jurisdiction: "move"
        //     },
        // },
        {
            path: '/moveService',
            component: () => import('@/moveViews/moveService/solution.vue'),
            meta: {
                title: '产品与服务',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/realname',
            component: () => import('@/moveViews/moveService/realname/index.vue'),
            meta: {
                title: '建筑工人实名制管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/towercrane',
            component: () => import('@/moveViews/moveService/towercrane/index.vue'),
            meta: {
                title: '塔机安全监控管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/lifter',
            component: () => import('@/moveViews/moveService/lifter/index.vue'),
            meta: {
                title: '升降机安全监控管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/loadhook',
            component: () => import('@/moveViews/moveService/loadhook/index.vue'),
            meta: {
                title: '塔机吊钩导航系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/surroundings',
            component: () => import('@/moveViews/moveService/surroundings/index.vue'),
            meta: {
                title: '环境监测管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/qualityinspection',
            component: () => import('@/moveViews/moveService/qualityinspection/index.vue'),
            meta: {
                title: '质量检测管理平台',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/video',
            component: () => import('@/moveViews/moveService/video/index.vue'),
            meta: {
                title: '视频监控管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/deeppit',
            component: () => import('@/moveViews/moveService/deeppit/index.vue'),
            meta: {
                title: '深基坑监测管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/contract',
            component: () => import('@/moveViews/moveService/contract/index.vue'),
            meta: {
                title: '合同管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/workerwage',
            component: () => import('@/moveViews/moveService/workerwage/index.vue'),
            meta: {
                title: '建筑工人工资管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/digital',
            component: () => import('@/moveViews/moveService/digital/index.vue'),
            meta: {
                title: 'OA协同办公管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/lifecycle',
            component: () => import('@/moveViews/moveService/lifecycle/index.vue'),
            meta: {
                title: '档案管理系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/visualization',
            component: () => import('@/moveViews/moveService/visualization/index.vue'),
            meta: {
                title: '大数据共享服务平台',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/colony',
            component: () => import('@/moveViews/moveService/colony/index.vue'),
            meta: {
                title: '门户网站建设及应用平台 [机构/企业]',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/overview',
            component: () => import('@/moveViews/moveService/overview/index.vue'),
            meta: {
                title: '分布式制能制造系统[物联感知平台]',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/standardSpecification',
            component: () => import('@/moveViews/moveService/standardSpecification/index.vue'),
            meta: {
                title: '数字化管理基础平台',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveService/digitalTwin',
            component: () => import('@/moveViews/moveService/digitalTwin/index.vue'),
            meta: {
                title: '数字孪生平台',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveSolution/concrete',
            component: () => import('@/moveViews/moveService/concrete/index.vue'),
            meta: {
                title: '混凝土质量动态追踪及检测监管系统',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveJournalism',
            component: () => import('@/moveViews/newsInformation/index.vue'),
            meta: {
                title: '新闻资讯',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveJournalism/allNewsParticulars',
            component: () => import('@/moveViews/newsInformation/allNewsParticulars/allNewsParticulars.vue'),
            meta: {
                title: '全部新闻详情',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveSuccessful',
            component: () => import('@/moveViews/successfulCase/index.vue'),
            meta: {
                title: '成功案例',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveSuccessful/successfulCaseList',
            component: () => import('@/moveViews/successfulCase/list/index.vue'),
            meta: {
                title: '益阳国家级数字经济产业园研发服务中心',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveSuccessful/successfulCaseXinjiang',
            component: () => import('@/moveViews/successfulCase/xinjiang/index.vue'),
            meta: {
                title: '新疆水利水电工程建设有限责任公司',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveSuccessful/successfulCaseSmartSite',
            component: () => import('@/moveViews/successfulCase/smartSite/index.vue'),
            meta: {
                title: '互联网+智慧工地解决方案',
                jurisdiction: "move"
            },
        },
        {
            path: '/movePropagandize',
            component: () => import('@/moveViews/movePropagandize/index.vue'),
            meta: {
                title: '宣传视频',
                jurisdiction: "move"
            },
        },
        {
            path: '/movePropagandize/details',
            component: () => import('@/moveViews/movePropagandize/videoDetails/videoDetails.vue'),
            meta: {
                title: '视频详情',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveDownload',
            component: () => import('@/moveViews/moveDownload/index.vue'),
            meta: {
                title: '软件下载',
                jurisdiction: "move"
            },
        },
        {
            path: '/moveAboutUs',
            component: () => import('@/moveViews/moveAboutUs/index.vue'),
            meta: {
                title: '关于我们',
                jurisdiction: "move"
            },
        },
    ]
}]


var routes = []
if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    routes = routersM
} else {
    routes = routersPC
}

//创建并暴露一个路由器 
const router = new vueRouter({
    routes
})
export default router