<template>
  <div>
    <div class="chunk">
      <el-image :src="require('@/assets/moveViews/componentss/logo.png')" class="logo-style" />
      <el-dropdown @command="handleCommand" trigger="click" class="more">
        <span>
          <el-image :src="require('@/assets/moveViews/componentss/more.png')" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/">首页</el-dropdown-item>
          <el-dropdown-item command="/moveService">产品与服务</el-dropdown-item>
          <el-dropdown-item command="/moveJournalism">新闻资讯</el-dropdown-item>
          <el-dropdown-item command="/moveSuccessful">成功案例</el-dropdown-item>
          <el-dropdown-item command="/moveAboutUs">关于我们</el-dropdown-item>
          <el-dropdown-item command="/moveDownload">软件下载</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
export default {
  name: 'HomeAppHeadBar',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {
    handleCommand(command) {
      this.$router.push({ path: command });
    }
  },
};
</script>

<style scoped>
.chunk {
  overflow: hidden;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-style {
  width: 35%;
}

.more {
  width: 6%;
  margin-right: 4%;
}

/* el-dropdown样式 */
.el-dropdown-menu {
  position: absolute;
  top: 50px !important;
  left: 67% !important;
  z-index: 10;
  padding: 0px 0;
  margin: 0px;
  background-color: #FFF;
  border: 1px solid #EBEEF5;
  border-radius: 0px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  width: 32%;
}

.el-dropdown-menu__item {
  color: #333333;
  font-weight: 400;
}

/* 去掉el-dropdown的小三角 */
.el-popper {
  overflow: auto;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0px 0 0px 0;
}
</style>